import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Button, Modal } from '../..';
import { FormattedMessage } from '../../../util/reactIntl';

import css from './RecommendationPopup.module.css';

import * as validators from '../../../util/validators';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_AFTER_LOGIN, RESET_ACTION_AFTER_LOGIN, signup } from '../../../ducks/Auth.duck';
import WorktrippOverViewForm from '../components/WorktrippOverViewForm';
import WorktrippSupportForm from '../components/WorktrippSupportForm';
import { DEFUALT_WORLD_ID } from '../../../default-location-searches';

import VenueForm from '../components/VenueForm';
import WorkshopRequirementsForm from '../components/WorkshopRequirementsForm';
import SignUpForm from '../components/SignUpForm';
import config from '../../../config';
import routeConfiguration from '../../../routeConfiguration';
import defaultLocations from '../../../default-location-searches';
import { validFilterParams } from '../../../containers/SearchPage/SearchPage.helpers';
import { createResourceLocatorString } from '../../../util/routes';
import { filters } from '../../../marketplace-custom-config';

const RecommendationPopup = ({
  currentUser,
  toggleDisplay,
  setToggleDisplay,
  selectedWorktripp,
}) => {
  const dispatch = useDispatch();
  const { Auth: authReducer } = useSelector(state => state);
  const { signupError, loginErrorMsg, actionAfterLogin } = authReducer;
  const scrollRef = useRef(null);
  const executeScroll = () => scrollRef.current.scrollIntoView();

  const [venueVibe, setVenueVibe] = useState([]);
  const [goals, setGoals] = useState([]);
  const [workshopOrActivityRequired, setWorkshopOrActivityRequired] = useState('no');
  const [listingTypes, setListingTypes] = useState([]);
  const [levelOfExperience, setLevelOfExperience] = useState([]);
  const [experienceType, setExperienceType] = useState([]);
  const [supportAreas, setSupportAreas] = useState([]);
  const [location, setLocation] = useState([]);

  const [worktrippTemp, setWorktrippTemp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  const isLoggedIn = currentUser?.id?.uuid;

  const requiresVenue = useMemo(() => listingTypes.includes('venue'), [listingTypes]);

  const hideChat = isOpen => {
    const getElement = document.querySelectorAll('.crisp-client');

    getElement.forEach(element => {
      if (!isOpen) element.style.display = 'none';
      else element.style.display = 'block';
    });
  };
  useEffect(() => {
    hideChat(toggleDisplay);
  }, [toggleDisplay]);

  useEffect(() => {
    if (loginErrorMsg) {
      setErrorMessage(loginErrorMsg);
      setLoading(false);
    } else if (signupError) {
      setErrorMessage(signupError);
      setLoading(false);
    }
  }, [signupError, loginErrorMsg]);

  useEffect(() => {
    if (actionAfterLogin == 'openSpecificTab' && !isSubmit && worktrippTemp) {
      setIsSubmit(true);
      redirectToSearchPage(worktrippTemp, redirectedTab());
    }
  }, [actionAfterLogin]);

  const redirectedTab = () => {
    let tab = 'all';
    if (requiresVenue) {
      tab = 'venue';
    } else if (workshopOrActivityRequired) {
      if (listingTypes.includes('retreat')) {
        tab = 'retreat';
      } else if (listingTypes.includes('expertFacilitator')) {
        tab = 'certifiedCoach,domainSpecialistCoach,expertFacilitator,inspiredSpeaker';
      } else if (listingTypes.includes('experiences')) {
        tab = 'experiences';
      } else if (listingTypes.includes('certifiedCoach')) {
        tab = 'certifiedCoach,domainSpecialistCoach,expertFacilitator,inspiredSpeaker';
      }
    }
    return tab;
  };

  const redirectToSearchPage = (worktripp, tab) => {
    const filterConfig = config.custom.filters;
    const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};
    const routes = routeConfiguration();
    const { location, venueVibe, goals, experiences, minBudget, budgetPerPerson, ...rest } =
      worktripp || {};

    let mappedGoals = [];

    const goalOptions = filters.find(filter => filter.id == 'goals')?.config.options;

    goals?.forEach(goal => {
      const filteredOptionsForSeletedGoal =
        goalOptions.filter(option => option.parent == goal) || [];
      filteredOptionsForSeletedGoal?.forEach(option => mappedGoals.push(option.key));
    });

    const pub_venueVibe = venueVibe || [];
    const pub_specialism = mappedGoals;
    const pub_listingSector = experiences || [];
    const { address, bounds } =
      defaultLocations.find(defaultLocation => defaultLocation.id == location)?.predictionPlace ||
      {};
    const priceValue = minBudget || budgetPerPerson?.amount / 100;
    const price = priceValue ? `0,${priceValue}` : '';

    const searchParams = {
      address,
      ...originMaybe,
      bounds: bounds,
      mapSearch: true,
      ...validFilterParams(
        { pub_venueVibe, pub_specialism, pub_listingSector, price },
        filterConfig
      ),
    };
    const redirectUrl = createResourceLocatorString(
      'SearchPage',
      routes,
      { slug: tab },
      searchParams
    );

    setTimeout(() => {
      window.open(redirectUrl, '_top');
    }, 2500);
  };

  const pages = [
    {
      title: "Let's start with the basics",
      body: (
        <WorktrippOverViewForm
          validators={validators}
          css={css}
          goals={goals}
          setGoals={setGoals}
          location={location}
          setLocation={setLocation}
        />
      ),
    },
    {
      title: 'How can we help?',
      body: (
        <WorktrippSupportForm
          validators={validators}
          css={css}
          listingTypes={listingTypes}
          setListingTypes={setListingTypes}
          venueVibe={venueVibe}
          setVenueVibe={setVenueVibe}
          supportAreas={supportAreas}
          setSupportAreas={setSupportAreas}
        />
      ),
    },
    // {
    //   title: 'Workshops & Activities',
    //   body: (
    //     <WorkshopRequirementsForm
    //       css={css}
    //       workshopOrActivityRequired={workshopOrActivityRequired}
    //       setWorkshopOrActivityRequired={setWorkshopOrActivityRequired}
    //       listingTypes={listingTypes}
    //       setListingTypes={setListingTypes}
    //       levelOfExperience={levelOfExperience}
    //       setLevelOfExperience={setLevelOfExperience}
    //       experienceType={experienceType}
    //       setExperienceType={setExperienceType}
    //     />
    //   ),
    // },
  ];

  if (!isLoggedIn) {
    pages.push({
      title: (
        <span>
          Do you want to save these <br />
          search filters for later?
        </span>
      ),
      body: (
        <SignUpForm
          validators={validators}
          css={css}
          subTitle="Shorten your time to enquiries by signing up to your free account now."
        />
      ),
    });
  }

  const [currentPage, setCurrentPage] = useState(1);

  const nextPage = () => {
    executeScroll();
    setCurrentPage(prevPage => Math.min(prevPage + 1, pages.length));
  };

  const previousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const onSubmit = async (values, proceedWithSignUp = true) => {
    // Handle form submission logic
    setErrorMessage(null);

    if (loading) return;

    const experienceMap = {
      active: ['outdoor', 'sports', 'onWater'],
      foodie: ['FnD'],
      creative: ['art', 'theatre', 'problemSolving'],
      wellbeing: ['nature', 'yoga', 'spa'],
    };
    let experiences = [];
    experienceType.forEach(experience => {
      if (experienceMap[experience]) {
        experiences = [...experienceMap[experience], ...experiences];
      }
    });

    // Note: for now we are only able to look-up one location
    const singularLocation = location?.length === 1 ? location[0] : DEFUALT_WORLD_ID;

    try {
      setLoading(true);
      let worktripp = {
        ...values,
        venueVibe,
        goals,
        listingTypes,
        levelOfExperience,
        experiences,
        location: singularLocation,
        supportAreas,
      };

      if (!values.email) {
        worktripp = {
          ...worktripp,
          email: isLoggedIn ? currentUser?.attributes?.email : 'no email',
        };
      }

      let worktrippExist = worktrippTemp?._id;
      if (!worktrippExist) {
        const { data } = await axios.post('/api/worktripp-onboarding', worktripp);
        worktrippExist = data._id;
      } else {
        worktripp = worktrippTemp;
      }
      if (isLoggedIn) return redirectToSearchPage(worktripp, redirectedTab());
      if (worktrippExist) {
        if (!proceedWithSignUp) {
          // if user skips sign up save in local storage to use for display
          delete worktripp.password;
          localStorage.setItem(
            'tempWorktripp',
            JSON.stringify({
              ...worktripp,
              _id: worktrippExist,
            })
          );
          setIsSubmit(true);
          redirectToSearchPage(worktripp, redirectedTab());
        } else {
          const { email, password, firstName, lastName, organisationName, jobtitle } = values;
          setWorktrippTemp(worktripp);

          dispatch(
            signup(
              {
                email,
                password,
                firstName,
                lastName,
                organisationName,
                jobtitle,
                interestedIn: 'Booking a WorkTripp',
              },
              worktrippExist,
              'openSpecificTab'
            )
          );
        }
      }
    } catch (err) {
      setLoading(false);
      setErrorMessage(err?.message ? err.message : 'Failed to process the request');
      console.log(err);
    }
  };

  const checkDisabled = (values, selectedPage) => {
    if (loading) return true;
    const {
      noOfParticipants,
      startDate,
      endDate,
      budgetPerPerson,
      firstName,
      lastName,
      email,
      password,
      organisationName,
      jobtitle,
      title,
    } = values;
    const pageToCheck = selectedPage || currentPage;

    switch (pageToCheck) {
      case 1:
        return !(
          title &&
          noOfParticipants &&
          startDate &&
          endDate &&
          budgetPerPerson &&
          goals.length > 0 &&
          location?.length > 0
        );
      case 2:
        return listingTypes.length === 0 || (requiresVenue && venueVibe.length === 0);
      case 3:
        return !(firstName && lastName && email && password && organisationName && jobtitle);
      default:
        return true;
    }
  };

  const submitModalPage = () => {
    return (
      <div style={{ padding: 40, textAlign: 'center' }}>
        <h1>
          Great selection! <span style={{ fontSize: '64px' }}>🙌</span>
        </h1>
        <h2>Start by choosing your venue</h2>
        <span>
          We’ll take you to the workshops and activities later - or you can see them now via the top
          tabs.
        </span>
      </div>
    );
  };

  const formModalPage = () => {
    const { title, body } = pages[currentPage - 1] || {};

    const renderDots = (form, values) => {
      if (pages.length <= 1) {
        return <div />;
      }
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {pages.map((page, pageIndex) => {
            let canProceed = true;
            for (var i = 0; i < pageIndex; i++) {
              const notAllValuesProvided = checkDisabled(values, i + 1);
              if (notAllValuesProvided) canProceed = false;
            }
            return (
              <span
                className={classNames(
                  css.dot,
                  pageIndex + 1 == currentPage && css.selected,
                  !canProceed && css.dotDisabled
                )}
                key={pageIndex + 1}
                onClick={() => {
                  if (canProceed) {
                    setCurrentPage(pageIndex + 1);
                    executeScroll();
                    form.change('page', pageIndex + 1);
                  }
                }}
              ></span>
            );
          })}
        </div>
      );
    };

    const renderNavButtons = (form, values) => {
      if (currentPage < pages.length) {
        return (
          <div className={classNames(css.setDisplayFlex, css.setFlexBottom)}>
            <div
              onClick={() => {
                setToggleDisplay(false);
                hideChat(true);
              }}
              style={{
                marginRight: '41px',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              <FormattedMessage id="ChatActionsSection.cancelButton" />
            </div>
            <Button
              type="button"
              onClick={() => {
                nextPage();
                form.change('page', currentPage + 1);
              }}
              disabled={checkDisabled(values)}
              className={css.buttonType}
            >
              <FormattedMessage id="RecommendationPopup.Next" />
            </Button>
          </div>
        );
      }

      if (!isLoggedIn) {
        return (
          <div className={css.buttonWrapper}>
            <a
              className={css.linkType}
              onClick={e => {
                onSubmit(values, false);
                hideChat(true);
              }}
              style={{
                marginRight: '10px',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              <FormattedMessage id="RecommendationPopup.skipSignUp" />
            </a>
            <Button
              type="submit"
              className={css.buttonType}
              style={{ marginLeft: '1rem' }}
              disabled={checkDisabled(values)}
            >
              <FormattedMessage id="AuthenticationPage.signupLinkText" />
            </Button>
          </div>
        );
      }

      return (
        <div className={classNames(css.setDisplayFlex, css.setFlexBottom)}>
          <div
            onClick={() => {
              setToggleDisplay(false);
              hideChat(true);
            }}
            style={{
              marginRight: '41px',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            <FormattedMessage id="ChatActionsSection.cancelButton" />
          </div>
          <Button type="submit" className={css.buttonType} disabled={checkDisabled(values)}>
            <FormattedMessage id="RecommendationPopup.submit" />
          </Button>
        </div>
      );
    };

    return (
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <h1 style={{ textAlign: 'center', fontSize: '30px', marginTop: '24px' }}>{title}</h1>
              <hr style={{ borderBottom: '1px solid #bbb' }}></hr>
            </div>
            <div className={css.formBody}>
              <div ref={scrollRef}></div>
              <div key={currentPage - 1}>{body}</div>
            </div>
            {errorMessage && (
              <div style={{ color: 'red', marginTop: '24px', marginBottom: '24px' }}>
                {errorMessage}
              </div>
            )}
            <div className={css.setBottomFlex}>
              {renderDots(form, values)}
              {renderNavButtons(form, values)}
            </div>
          </form>
        )}
      />
    );
  };

  return (
    <Modal
      id="RecommendationPopup"
      isOpen={toggleDisplay}
      onClose={() => setToggleDisplay(false)}
      onManageDisableScrolling={() => {}}
      closeButtonMessage="hidden"
      containerClassName={css.container}
    >
      {isSubmit ? submitModalPage() : formModalPage()}
    </Modal>
  );
};

export default RecommendationPopup;
