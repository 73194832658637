import React from 'react';
import IconButton from '../../IconButton/IconButton';

const MultipleChoice = ({ css, singleChoice, title, items, selectedValues, setSelectedValues }) => {
  return (
    <div style={{ paddingBottom: 10 }}>
      <label style={{ paddingBottom: 10 }}>{title}</label>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {items.map(item => (
          <IconButton
            key={item.key}
            value={item.key}
            title={item.title}
            setBackground={css.setWithoutIconButton}
            withoutIcon={true}
            singleChoice={singleChoice}
            values={selectedValues}
            setValues={setSelectedValues}
          />
        ))}
      </div>
    </div>
  );
};

export default MultipleChoice;
