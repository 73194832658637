import React, { useEffect, useMemo } from 'react';
import MultipleChoice from './MultipleChoice';

import Group1 from '../../../assets/icons/Group1.svg';
import Group2 from '../../../assets/icons/Group2.svg';
import Group3 from '../../../assets/icons/Group3.svg';

const LISTING_TYPES = {
  venue: {
    title: 'Venue',
    key: 'venue',
  },
  retreat: {
    title: 'Retreat organisers',
    key: 'retreat',
    expand:
      'Retreat organisers are experienced offsite designers, who will plan, book and often host an offsite on your behalf.',
  },
  expertFacilitator: {
    title: 'Workshop facilitators',
    key: 'expertFacilitator',
    expand:
      'Facilitators are trained to guide and manage group discussions and activities to achieve specific goals or outcomes.',
  },
  experiences: {
    title: 'Away day activities',
    key: 'experiences',
    expand:
      "These are high-impact team activities that don't require an overnight stay. NB: most of our venues recommend or provide local options if you're staying with them.",
  },
  certifiedCoach: {
    title: 'Team coaches',
    key: 'certifiedCoach',
    expand:
      'Team coaches facilitate learning and development for the team as a whole, rather than as individuals. All WorkTripp certified coaches meet ICF standards.',
  },
};

const VENUE_TYPES = [
  {
    key: 'luxe',
    setImage: Group1,
    title: 'Luxe',
    description: '5-star facility and service',
  },
  {
    key: 'effortless',
    setImage: Group2,
    title: 'Effortless',
    description: '3-4 star, everything that you need for easy, comfort',
  },
  {
    key: 'wild',
    setImage: Group3,
    title: 'Wild',
    description: 'Really rural with basic facilities',
  },
];

export const SUPPORT_AREAS = [
  { title: 'Travel logistics', key: 'travelLogistics' },
  { title: 'Budgeting', key: 'budgeting' },
  { title: 'Programming / Agenda', key: 'programmingAgenda' },
  { title: 'Impact & insights', key: 'impactInsights' },
  { title: 'Offsite strategy', key: 'offsiteStrategy' },
  { title: 'Year-round support', key: 'yearRoundSupport' },
];

const WorktrippSupportForm = ({
  css,
  listingTypes,
  setListingTypes,
  venueVibe,
  setVenueVibe,
  supportAreas,
  setSupportAreas,
}) => {
  const isVenueSelected = useMemo(() => listingTypes.includes(LISTING_TYPES.venue.key), [
    listingTypes,
  ]);

  useEffect(() => {
    if (!isVenueSelected) {
      setVenueVibe([]);
    }
  }, [isVenueSelected]);

  return (
    <div>
      <br />
      <MultipleChoice
        css={css}
        title="What are you looking for today?"
        items={Object.values(LISTING_TYPES)}
        selectedValues={listingTypes}
        setSelectedValues={setListingTypes}
      />
      <br />
      {isVenueSelected && (
        <>
          <MultipleChoice
            css={css}
            title="What type of venue are you looking for?"
            items={VENUE_TYPES}
            withoutIcon={false}
            selectedValues={venueVibe}
            setSelectedValues={setVenueVibe}
          />
          <br />
        </>
      )}

      <MultipleChoice
        css={css}
        title="Do you need extra support around...?"
        items={SUPPORT_AREAS}
        selectedValues={supportAreas}
        setSelectedValues={setSupportAreas}
      />
    </div>
  );
};

export default WorktrippSupportForm;
