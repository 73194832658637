import React from 'react';
import IconButton from '../../IconButton/IconButton';
import FieldTextInput from '../../FieldTextInput/FieldTextInput';
import FieldDateInput from '../../FieldDateInput/FieldDateInput';
import FieldDateRangeInput from '../../FieldDateRangeInput/FieldDateRangeInput';
import FieldCurrencyInput from '../../FieldCurrencyInput/FieldCurrencyInput';
import MultipleChoice from './MultipleChoice';
import { currencyConfiguration } from '../../../currency-config';
import { goalsLabels } from '../../../marketplace-custom-config';
import { DEFAULT_LOCATIONS } from '../../../default-location-searches';
import { FormattedMessage } from 'react-intl';

const WorktrippOverViewForm = props => {
  const { validators, css, goals, setGoals, location, setLocation } = props;
  const dateRequired = <FormattedMessage id="ChatActionsSection.dateRequired" />;
  const teamSizeRequired = <FormattedMessage id="ChatActionsSection.teamSizeRequired" />;
  const titleRequired = <FormattedMessage id="ChatActionsSection.titleRequired" />;
  const budgetRequired = <FormattedMessage id="ChatActionsSection.budgetRequired" />;

  return (
    <div>
      <MultipleChoice
        css={css}
        title="Our goals for this offsite are..."
        items={goalsLabels}
        selectedValues={goals}
        setSelectedValues={setGoals}
      />

      <FieldTextInput
        type="number"
        className={css.field}
        name="noOfParticipants"
        id="noOfParticipants"
        label="We're booking for a team of..."
        placeholder="E.g 23"
        validate={validators.required(teamSizeRequired)}
      />
      <FieldDateInput
        className={css.field}
        rootClassName={css.zIndex}
        type="date"
        id="startDate"
        name="startDate"
        label="We're looking at dates after..."
        placeholderText="xx/xx/xx"
        validate={validators.required(dateRequired)}
      />
      <FieldDateInput
        className={css.field}
        rootClassName={css.zIndex2}
        type="date"
        id="endDate"
        name="endDate"
        label="We're looking at dates before..."
        placeholderText="xx/xx/xx"
        validate={validators.required(dateRequired)}
      />
      <FieldCurrencyInput
        className={css.field}
        name="budgetPerPerson"
        id="budgetPerPerson"
        label="The per-person budget is... "
        placeholder="E.g 250"
        currencyConfig={currencyConfiguration('GBP')}
        validate={validators.required(budgetRequired)}
      />
      <MultipleChoice
        css={css}
        singleChoice
        title="What location are you considering?"
        items={DEFAULT_LOCATIONS}
        selectedValues={location}
        setSelectedValues={setLocation}
      />

      <FieldTextInput
        type="text"
        className={css.field}
        name="title"
        id="title"
        label="Give your WorkTripp a name"
        placeholder="Retreat to.."
        validate={validators.required(titleRequired)}
      />
    </div>
  );
};

export default WorktrippOverViewForm;
